<template>
  <div>

    <div style="padding:10px 50px;">
      <h3 style="text-align:left; color:#696969;">{{ $store.state.common.sScreenTitle }}</h3>
      <hr/>
    </div>
  
    <div v-show="$store.state.common.sIsLoading">
      Loading...
    </div>
    <div v-show="!$store.state.common.sIsLoading">
      <div v-show="!vOperable">
        <h4 style="color:#ffffff;">職場情報が未登録です。</h4>
        <div class="uk-flex uk-flex-center">
          <div class="uk-container">
            <div class="uk-text-center" uk-grid>
              <div style="width:100%; text-align:right;">
                <button class="uk-button uk-button-default" type="button" @click="doBackToHome">戻る</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div v-show="vOperable">
  
        <div style="padding:10px;">
          <div style="width:100%; text-align:right; margin-bottom:10px;">
            <button type="button" class="operate-button" style="width:10%; line-height:3; background-color:#ffffff; border-radius:10px; border:solid 1px #696969; color:#696969;" @click="doSwitchInputArea(0)">
              再表示
            </button>
            <button type="button" class="operate-button" style="width:10%; line-height:3; background-color:#ffffff; border-radius:10px; border:solid 1px #696969; color:#696969;" @click="doSwitchInputArea(1)" :style="vUpdateMode == 1 ? nowUpdateMode: ''">
              追加
            </button>
            <button type="button" class="operate-button" style="width:10%; line-height:3; background-color:#ffffff; border-radius:10px; border:solid 1px #696969; color:#696969;" @click="doSwitchInputArea(2)" :style="vUpdateMode == 2 ? nowUpdateMode: ''">
              変更
            </button>
            <button type="button" class="operate-button" style="width:10%; line-height:3; background-color:#ffffff; border-radius:10px; border:solid 1px #696969; color:#696969;" @click="doSwitchInputArea(3)" :style="vUpdateMode == 3 ? nowUpdateMode: ''">
              削除
            </button>
          </div>
          <div style="width:100%; text-align:right; margin-bottom:10px;">
            <button :disabled="$store.state.common.sServerProcessing" type="button" class="operate-button" style="width:20%; line-height:3; background-color:#ffffff; border-radius:10px; border:solid 1px #696969; color:#696969;" @click="doDownloadFile">
              一括取込用ファイル取得
            </button>
            <button :disabled="$store.state.common.sServerProcessing" type="button" class="operate-button" style="width:20%; line-height:3; background-color:#ffffff; border-radius:10px; border:solid 1px #696969; color:#696969;" @click="doFireInputFile">
              一括取込用ファイル選択
            </button>
            <input type="file" :id="vIdInputFile" accept=".xlsx,.xls" @change="doChangeFile" style="display:none;">
          </div>
        </div>
  
        <!-- データ一覧 -->
        <div style="padding:10px;">
          <div style="width:100%; text-align:center; margin-bottom:10px; height:200px; overflow:scroll;">
            <table class="sc-table" border="1">
              <thead>
                <th>行№</th>
                <th>{{ $store.state.employee.sLblEmpCode }}</th>
                <th>{{ $store.state.employee.sLblEmpName }}</th>
                <th>{{ $store.state.employee.sLblEmpKana }}</th>
                <th>{{ $store.state.employee.sLblEmpWpId }}</th>
                <th>{{ $store.state.employee.sLblEmpBirthday }}</th>
                <th>{{ $store.state.employee.sLblEmpSex }}</th>
                <th>{{ $store.state.employee.sLblEmpMailAddress }}</th>
                <th>{{ $store.state.employee.sLblRetireStatus }}</th>
                <th>{{ $store.state.employee.sLblRetireDate }}</th>
              </thead>
              <tbody>
                <tr v-for="tDat,idx in vTblListData" :key="tDat.emp_code" :id="'cus-emp-' + tDat.emp_code" @click="doStorageRowInfo(tDat.emp_code)">
                  <td>{{ idx + 1 }}</td>
                  <td>{{ tDat.emp_code }}</td>
                  <td>{{ tDat.emp_name }}</td>
                  <td>{{ tDat.emp_kana }}</td>
                  <td>{{ tDat.wp_name }}</td>
                  <td>{{ tDat.emp_birthday_disp }}</td>
                  <td>{{ tDat.emp_sex_name }}</td>
                  <td>{{ tDat.emp_mail_address }}</td>
                  <td>{{ tDat.retire_status_name }}</td>
                  <td>{{ tDat.retire_date }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
  
        <hr/>
  
        <!-- 入力エリア -->
        <div v-show="vUpdateMode == 1 || (vSelectedRow && (vUpdateMode == 2 || vUpdateMode == 3))" class="uk-flex uk-flex-center uk-margin">
          <div class="uk-container uk-width-1-1">
            <div class="uk-text-center" uk-grid>
              <div class="uk-width-1-1" style="text-align:left;">
                <h4 style="color:#696969;">入力欄</h4>
              </div>
              <div class="uk-width-1-3">
                <div class="uk-margin">
                  <div style="text-align:left">
                    <label class="uk-form-label uk-text-muted">{{ $store.state.employee.sLblEmpCode }}</label>
                    <span style="background-color:#ff0000; color:#ffffff; font-size:0.5em; border-radius:20px; padding:2px; box-sizing:border-box;"><b>必須</b></span>
                  </div>
                  <input class="uk-input" type="text" v-model="vEmpCode" :disabled="vUpdateMode == 2 || vUpdateMode == 3">
                </div>
              </div>
              <div class="uk-width-1-3">
                <div class="uk-margin">
                  <div style="text-align:left">
                    <label class="uk-form-label uk-text-muted">{{ $store.state.employee.sLblEmpName }}</label>
                    <span style="background-color:#ff0000; color:#ffffff; font-size:0.5em; border-radius:20px; padding:2px; box-sizing:border-box;"><b>必須</b></span>
                  </div>
                  <input class="uk-input" type="text" v-model="vEmpName" :disabled="vUpdateMode == 3">
                </div>
              </div>
              <div class="uk-width-1-3">
                <div class="uk-margin">
                  <div style="text-align:left">
                    <label class="uk-form-label uk-text-muted">{{ $store.state.employee.sLblEmpKana }}</label>
                  </div>
                  <input class="uk-input" type="text" v-model="vEmpKana" :disabled="vUpdateMode == 3">
                </div>
              </div>
              <div class="uk-width-1-3">
                <div class="uk-margin">
                  <div style="text-align:left">
                    <label class="uk-form-label uk-text-muted">{{ $store.state.employee.sLblEmpWpId }}</label>
                    <span style="background-color:#ff0000; color:#ffffff; font-size:0.5em; border-radius:20px; padding:2px; box-sizing:border-box;"><b>必須</b></span>
                  </div>
                  <select class="uk-select" v-model="vEmpWpId" id="vEmpWpId" @change="doSetSelectedLabel('vEmpWpId')" :disabled="vUpdateMode == 3">
                    <option v-for="wpDat in vWpListData" :value="wpDat.wp_id" :key="wpDat.wp_id">{{ wpDat.wp_name }}</option>
                  </select>
                </div>
              </div>
              <div class="uk-width-1-3">
                <div class="uk-margin">
                  <div style="text-align:left">
                    <label class="uk-form-label uk-text-muted">{{ $store.state.employee.sLblEmpBirthday }}</label>
                    <span style="background-color:#ff0000; color:#ffffff; font-size:0.5em; border-radius:20px; padding:2px; box-sizing:border-box;"><b>必須</b></span>
                  </div>
                  <input class="uk-input" type="date" v-model="vEmpBirthday" :disabled="vUpdateMode == 3">
                </div>
              </div>
              <div class="uk-width-1-3">
                <div class="uk-margin">
                  <div style="text-align:left">
                    <label class="uk-form-label uk-text-muted">{{ $store.state.employee.sLblEmpSex }}</label>
                    <span style="background-color:#ff0000; color:#ffffff; font-size:0.5em; border-radius:20px; padding:2px; box-sizing:border-box;"><b>必須</b></span>
                  </div>
                  <select class="uk-select" v-model="vEmpSex" id="vEmpSex" @change="doSetSelectedLabel('vEmpSex')" :disabled="vUpdateMode == 3">
                    <option v-for="nDat2 in vNameListData2" :value="nDat2.detail_name_id" :key="nDat2.detail_name_id">{{ nDat2.detail_display_name1 }}</option>
                  </select>
                </div>
              </div>
              <div class="uk-width-1-3">
                <div class="uk-margin">
                  <div style="text-align:left">
                    <label class="uk-form-label uk-text-muted">{{ $store.state.employee.sLblEmpMailAddress }}</label>
                  </div>
                  <input class="uk-input" type="text" v-model="vEmpMailAddress" :disabled="vUpdateMode == 3">
                </div>
              </div>
              <div class="uk-width-1-3">
                <div class="uk-margin">
                  <div style="text-align:left">
                    <label class="uk-form-label uk-text-muted">{{ $store.state.employee.sLblRetireStatus }}</label>
                  </div>
                  <select class="uk-select" v-model="vRetireStatus" id="vRetireStatus" @change="doSetSelectedLabel('vRetireStatus')" :disabled="vUpdateMode == 3">
                    <option v-for="nDat3 in vNameListData3" :value="nDat3.detail_name_id" :key="nDat3.detail_name_id">{{ nDat3.detail_display_name1 }}</option>
                  </select>
                </div>
              </div>
              <div class="uk-width-1-3">
                <div class="uk-margin">
                  <div style="text-align:left">
                    <label class="uk-form-label uk-text-muted">{{ $store.state.employee.sLblRetireDate }}</label>
                  </div>
                  <input class="uk-input" type="date" v-model="vRetireDate" :disabled="vUpdateMode == 3">
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div style="padding:10px;">
          <div style="width:100%; text-align:right; margin-bottom:10px;">
            <button v-show="vUpdateMode == 1 || (vSelectedRow && vUpdateMode == 2)" type="button" class="operate-button" style="width:10%; line-height:3; background-color:#696969; border-radius:10px; border:solid 1px #696969; color:#ffffff;" @click="doConfirmUpdate(vModalId)">
              更新
            </button>
            <button v-show="vUpdateMode == 3 && vSelectedRow" type="button" class="operate-button" style="width:10%; line-height:3; background-color:#c71585; border-radius:10px; border:solid 1px #696969; color:#ffffff;" @click="doConfirmUpdate(vModalId)">
              削除
            </button>
          </div>
        </div>
  
        <!-- 何かメッセージ出す用 -->
        <p><b>{{ vMessage }}</b></p>
  
        <!-- 更新時モーダル -->
        <div :id="vModalId" uk-modal>
          <div class="uk-modal-dialog">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-header">
              <h3 class="uk-modal-title" style="color:#696969;">確認</h3>
            </div>
            <div class="uk-modal-body" uk-overflow-auto>
              <p style="color:#696969;">{{ $store.state.common.sUpdateModalMessage }}</p>
              <div style="padding:10px;">
                <div style="width:100%; text-align:center; margin-bottom:10px;">
                  <table class="sc-table" border="1">
                    <tr>
                      <th class="sc-th">{{ $store.state.employee.sLblEmpCode }}</th>
                      <td>{{ vEmpCode }}</td>
                    </tr>
                    <tr>
                      <th class="sc-th">{{ $store.state.employee.sLblEmpName }}</th>
                      <td>{{ vEmpName }}</td>
                    </tr>
                    <tr>
                      <th class="sc-th">{{ $store.state.employee.sLblEmpKana }}</th>
                      <td>{{ vEmpKana }}</td>
                    </tr>
                    <tr>
                      <th class="sc-th">{{ $store.state.employee.sLblEmpWpId }}</th>
                      <td>{{ vEmpWpIdName }}</td>
                    </tr>
                    <tr>
                      <th class="sc-th">{{ $store.state.employee.sLblEmpBirthday }}</th>
                      <td>{{ vEmpBirthdayDisp }}</td>
                    </tr>
                    <tr>
                      <th class="sc-th">{{ $store.state.employee.sLblEmpSex }}</th>
                      <td>{{ vEmpSexName }}</td>
                    </tr>
                    <tr>
                      <th class="sc-th">{{ $store.state.employee.sLblEmpMailAddress }}</th>
                      <td>{{ vEmpMailAddress }}</td>
                    </tr>
                    <tr>
                      <th class="sc-th">{{ $store.state.employee.sLblRetireStatus }}</th>
                      <td>{{ vRetireStatusName }}</td>
                    </tr>
                    <tr>
                      <th class="sc-th">{{ $store.state.employee.sLblRetireDate }}</th>
                      <td>{{ vRetireDateDisp }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
              <div style="padding:10px;">
                <div style="width:100%; text-align:right; margin-bottom:10px; display:flex; justify-content:space-between;">
                  <button type="button" class="operate-button" style="width:20%; line-height:3; background-color:#ffffff; border-radius:10px; border:solid 1px #696969; color:#696969;" @click="doHideModal(vModalId)">
                    キャンセル
                  </button>
                  <button :disabled="$store.state.common.sServerProcessing" type="button" class="operate-button" style="width:20%; line-height:3; background-color:#696969; border-radius:10px; border:solid 1px #696969; color:#ffffff;" @click="doUpdateInfo">
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- 一括取込結果モーダル -->
      <div :id="vModalIdBulk" uk-modal>
        <div class="uk-modal-dialog uk-width-auto">
          <button class="uk-modal-close-default" type="button" uk-close></button>
          <div class="uk-modal-header">
            <h3 class="uk-modal-title" style="color:#696969;">取込確認</h3>
          </div>
          <div class="uk-modal-body" uk-overflow-auto>
            <p style="color:#696969;">取込を確定しますか？<small style="color:#ff0000;">（※エラーのあるレコードは取り込まれません）</small></p>
            <div style="padding:10px;">
              <div style="width:100%; text-align:center; margin-bottom:10px;">
                <table class="sc-table" border="1">
                  <thead>
                    <th>行№</th>
                    <th>{{ $store.state.employee.sLblEmpCode }}</th>
                    <th>{{ $store.state.employee.sLblEmpName }}</th>
                    <th>{{ $store.state.employee.sLblEmpKana }}</th>
                    <th>{{ $store.state.employee.sLblEmpWpId }}</th>
                    <th>{{ $store.state.employee.sLblEmpBirthday }}</th>
                    <th>{{ $store.state.employee.sLblEmpSex }}</th>
                    <th>{{ $store.state.employee.sLblEmpMailAddress }}</th>
                    <th>{{ $store.state.employee.sLblErrorDetail }}</th>
                  </thead>
                  <tbody>
                    <tr v-for="dtBulk,idx in vBulkData" :key="dtBulk.emp_code">
                      <td>{{ idx + 1 }}</td>
                      <td :class="doJudgeAddErrorClass(2, dtBulk.emp_code_error) ? '': vIsErrorCell">{{ dtBulk.emp_code }}</td>
                      <td>{{ dtBulk.emp_name }}</td>
                      <td>{{ dtBulk.emp_kana }}</td>
                      <td>{{ dtBulk.wp_name }}</td>
                      <td :class="doJudgeAddErrorClass(6, dtBulk.emp_birthday_error) ? '': vIsErrorCell">{{ dtBulk.emp_birthday_disp }}</td>
                      <td :class="doJudgeAddErrorClass(7, dtBulk.emp_sex_error) ? '': vIsErrorCell">{{ dtBulk.emp_sex_name }}</td>
                      <td>{{ dtBulk.emp_mail_address }}</td>
                      <td>{{ dtBulk.error_detail }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="uk-modal-footer uk-text-right">
            <div style="padding:10px;">
              <div style="width:100%; text-align:right; margin-bottom:10px; display:flex; justify-content:space-between;">
                <button type="button" class="operate-button" style="width:20%; line-height:3; background-color:#ffffff; border-radius:10px; border:solid 1px #696969; color:#696969;" @click="doHideModal(vModalIdBulk)">
                  キャンセル
                </button>
                <button :disabled="$store.state.common.sServerProcessing" v-show="sAbleUpdateBulk" type="button" class="operate-button" style="width:20%; line-height:3; background-color:#696969; border-radius:10px; border:solid 1px #696969; color:#ffffff;" @click="doUpdateInfoBulk">
                  OK
                </button>
              </div>
            </div>
  
          </div>
        </div>
      </div>
    </div>
  </div>

</template>



<script>
  import constant from '@/constant.js'
  import UIkit from 'uikit'
  import { saveAs } from "file-saver";

  export default {
    data() {
      return{
        vComId: this.$store.state.common.sComId,
        vTblListData: [],
        vWpListData: [],    // 職場情報
        vNameListData2: [], // 性別リスト
        vNameListData3: [], // 退職状態リスト
        vEmpCode: '',
        vEmpName: '',
        vEmpKana: '',
        vEmpWpId: '',
        vEmpWpIdName: '',
        vEmpBirthday: '',
        vEmpBirthdayDisp: '',
        vEmpSex: '',
        vEmpSexName: '',
        vEmpMailAddress: '',
        vLoginId: '',
        vLoginPw: '',
        vRetireStatus: '',
        vRetireStatusName: '',
        vRetireDate: '',
        vRetireDateDisp: '',
        vUpdateMode: constant.cPageOperationMode.nothing,
        vSelectedRow: false,
        vModalId: 'modal-confirm-customer-employee',
        vModalIdBulk: 'modal-customer-employee-bulk',
        vOperable: false,
        vFileName: this.$store.state.employee.sFileName,
        vIdInputFile: 'idInputFileEmp',
        vBulkData: [],
        sAbleUpdateBulk: false,
        // vSelectRowIndex: -1,
        vMessage: '',
        'nowUpdateMode': {
          'background-color': '#696969',
          'color': '#ffffff',
          'font-weight': 'bold'
        },
        'vIsErrorCell': 'bulk-error-cell',
        // 'vIsSelectRow': 'selectRow',
        vUlFile: ''
      }
    },
    created() {
      // 画面読み込み中・・・
      this.$store.state.common.sIsLoading = true;
      // ログイン状態判定
      this.doAuthLoginInfo()
      if (!this.$store.state.common.sIsLogined) {
        this.$router.push('/login')
      }
      // 画面タイトル
      this.$store.commit('common/doSetScreenTitle', {
        vScreenTitle: 'クライアント/社員情報'
      });
      // DB参照処理
      if (this.vComId) {
        this.vMessage = '';
        this.$axios.get(constant.cServerRoute.customer.employee, {
          params: {
            pComId: this.vComId
          }
        })
          .then(function(response){
            console.log(response);
            // 社員情報参照処理判定
            if (response.data.return_result == constant.cServerReturnResult.success) {
              // 社員情報を保持する
              if (response.data.return_data_emp) {
                this.vTblListData = response.data.return_data_emp;
              }
              // 職場情報を保持する
              if (response.data.return_data_wp) {
                this.vWpListData = response.data.return_data_wp;
              }
              if (this.vWpListData.length > 0) {
                this.vOperable = true;
              } else {
                return;
              }
              // 表示名情報を保持する（性別）
              if (response.data.return_data_name2) {
                this.vNameListData2 = response.data.return_data_name2;
              }
              // 表示名情報を保持する（退職状態）
              if (response.data.return_data_name3) {
                this.vNameListData3 = response.data.return_data_name3;
              }
            } else {
              this.vMessage = response.data.return_message;
            }
          }.bind(this))
          .catch(function(error){
            console.log(error);
            this.vMessage = error;
          })
      }
    },
    // 画面読み込み終わった？
    mounted() {
      this.$store.state.common.sIsLoading = false;
    },
    methods: {
      // DB更新処理
      doUpdateInfo() {
        this.vMessage = '';
        // 入力チェック
        this.$store.state.common.sAbleUpdate = true;
        this.doCheckValueBeforeUpdate();
        if (!this.$store.state.common.sAbleUpdate) {
          // モーダル閉じる
          this.doHideModal(this.vModalId);
          return;
        }
        // ボタン制御
        this.$store.commit('common/doStartServerProcess');
        // DB更新
        this.$axios.post(constant.cServerRoute.customer.employee, {
          pUpdateMode: this.vUpdateMode,
          pComId: this.vComId,
          pEmpCode: this.vEmpCode,
          pEmpName: this.vEmpName,
          pEmpKana: this.vEmpKana,
          pWpId: this.vEmpWpId,
          pEmpBirthday: this.vEmpBirthday,
          pEmpSex: this.vEmpSex,
          pEmpMailAddress: this.vEmpMailAddress,
          pRetireStatus: this.vRetireStatus,
          pRetireDate: this.vRetireDate
        })
          .then(function(response){
            console.log(response);
            // 社員情報更新処理判定
            if (response.data.return_result == constant.cServerReturnResult.success) {
              this.vTblListData = response.data.return_data_emp;
              // 更新メッセージ表示
              this.$store.commit('common/doNotificationUpdate', {
                'vMode': constant.cNotificationUpdateMode.success
              });
              // モーダル閉じる
              this.doHideModal(this.vModalId);
              // 値クリアしておく
              this.doClear();
              // ボタン制御
              this.$store.commit('common/doEndServerProcess');
            } else {
              // 更新メッセージ表示
              this.$store.commit('common/doNotificationUpdate', {
                'vMode': constant.cNotificationUpdateMode.failure
              });
              // モーダル閉じる
              this.doHideModal(this.vModalId);
              this.vMessage = response.data.return_message;
              // ボタン制御
              this.$store.commit('common/doEndServerProcess');
            }
          }.bind(this))
          .catch(function(error){
            // 更新メッセージ表示
            this.$store.commit('common/doNotificationUpdate', {
              'vMode': constant.cNotificationUpdateMode.failure
            });
            // モーダル閉じる
            this.doHideModal(this.vModalId);
            console.log(error);
            this.vMessage = error;
            // ボタン制御
            this.$store.commit('common/doEndServerProcess');
          })
      },
      // 更新前値チェック処理
      doCheckValueBeforeUpdate() {
        // 社員番号
        this.$store.commit('common/doCheckInput', {
          vVal: this.vEmpCode,
          vLabel: this.$store.state.employee.sLblEmpCode
        })
        // 社員名
        this.$store.commit('common/doCheckInput', {
          vVal: this.vEmpName,
          vLabel: this.$store.state.employee.sLblEmpName
        })
        // 職場名
        this.$store.commit('common/doCheckSelect', {
          vVal: this.vEmpWpId,
          vLabel: this.$store.state.employee.sLblEmpWpId
        })
        // 生年月日
        this.$store.commit('common/doCheckInput', {
          vVal: this.vEmpBirthday,
          vLabel: this.$store.state.employee.sLblEmpBirthday
        })
        // 性別
        this.$store.commit('common/doCheckSelect', {
          vVal: this.vEmpSex,
          vLabel: this.$store.state.employee.sLblEmpSex
        })
      },
      // // 一覧選択行の色設定判定
      // doJudgeAddSelectRowClass(rowIdx) {
      //   if (rowIdx == this.vSelectRowIndex) {
      //     return true;
      //   }
      //   return false;
      // },
      // 一括取込結果でセルにエラークラスを付与する判定
      doJudgeAddErrorClass(col, val) {
        switch (col) {
          // 社員番号が存在する
          case 2:
            if (val == 1) {
              return false;
            }
            break;
          // 生年月日が日付変換不可
          case 6:
            if (val == 1) {
              return false;
            }
            break;
          // 性別が不明
          case 7:
            if (val == 1) {
              return false;
            }
            break;
        }
        return true
      },
      // 一括取込用ファイルをダウンロードする
      doDownloadFile() {
        // ボタン制御
        this.$store.commit('common/doStartServerProcess');
        this.doClear();
        this.$axios.get(constant.cServerRoute.customer.employee_file_download, {
          params: {
            pFileName: this.vFileName
          },
          responseType: "blob"
        })
          .then(function(response){
            console.log(response);
            const blob = new Blob([response.data], {
              type: response.data.type
            });
            saveAs(blob, this.vFileName);
            // ボタン制御
            this.$store.commit('common/doEndServerProcess');
          }.bind(this))
          .catch(function(error){
            console.log(error);
            this.vMessage = error;
            // ボタン制御
            this.$store.commit('common/doEndServerProcess');
          })
      },
      // fileタイプのinputのイベントを発生させる
      doFireInputFile() {
        this.doClear();
        var obj = document.getElementById(this.vIdInputFile);
        obj.value = '';
        obj.click();
      },
      // 一括取込用ファイル選択
      doChangeFile(e) {
        e.preventDefault();
        var files = e.target.files;
        this.vUlFile = files[0];
        this.doUploadToServer();
      },
      // 一括取込用ファイルをサーバーへ送る
      doUploadToServer() {
        // ボタン制御
        this.$store.commit('common/doStartServerProcess');
        this.sAbleUpdateBulk = false;
        var formData = new FormData();
        formData.append('vUlFile', this.vUlFile);
        formData.append('pComId', this.vComId);
        var config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };
        this.$axios.post(constant.cServerRoute.customer.employee_file_upload, formData, config)
          .then(function(response){
            console.log(response);
            this.vBulkData = response.data.return_data;
            if (this.vBulkData.length > 0) {
                this.doConfirmUpdate(this.vModalIdBulk);
            } else {
              // データなしメッセージ表示
              this.$store.commit('common/doNotificationDataNone');
              // ボタン制御
              this.$store.commit('common/doEndServerProcess');
              return;
            }
            // 更新可能判定
            if (response.data.return_data_update_count > 0) {
              this.sAbleUpdateBulk = true;
            }
            // ボタン制御
            this.$store.commit('common/doEndServerProcess');
          }.bind(this))
          .catch(function(error){
            console.log(error);
            this.vMessage = error;
          })
          document.getElementById(this.vIdInputFile).value = '';
          // ボタン制御
          this.$store.commit('common/doEndServerProcess');
      },
      // DB更新処理（一括取込）
      doUpdateInfoBulk() {
        // ボタン制御
        this.$store.commit('common/doStartServerProcess');
        this.vMessage = '';
        // DB更新
        this.$axios.post(constant.cServerRoute.customer.employee_create_bulk, {
          pComId: this.vComId
        })
          .then(function(response){
            console.log(response);
            // 社員情報更新処理判定
            if (response.data.return_result == constant.cServerReturnResult.success) {
              // 社員情報を保持する
              if (response.data.return_data_emp) {
                this.vTblListData = response.data.return_data_emp;
              }
              // 職場情報を保持する
              if (response.data.return_data_wp) {
                this.vWpListData = response.data.return_data_wp;
              }
              // 更新メッセージ表示
              this.$store.commit('common/doNotificationUpdate', {
                'vMode': constant.cNotificationUpdateMode.success
              });
              // モーダル閉じる
              this.doHideModal(this.vModalIdBulk);
              // 値クリアしておく
              this.doClear();
              // ボタン制御
              this.$store.commit('common/doEndServerProcess');
            } else {
              // 更新メッセージ表示
              this.$store.commit('common/doNotificationUpdate', {
                'vMode': constant.cNotificationUpdateMode.failure
              });
              // モーダル閉じる
              this.doHideModal(this.vModalId);
              this.vMessage = response.data.return_message;
              // ボタン制御
              this.$store.commit('common/doEndServerProcess');
            }
          }.bind(this))
          .catch(function(error){
            // 更新メッセージ表示
            this.$store.commit('common/doNotificationUpdate', {
              'vMode': constant.cNotificationUpdateMode.failure
            });
            // モーダル閉じる
            this.doHideModal(this.vModalId);
            console.log(error);
            this.vMessage = error;
            // ボタン制御
            this.$store.commit('common/doEndServerProcess');
          })
      },
      // DB更新前確認処理
      doConfirmUpdate(modalId) {
        // UIkit.modal('#' + this.vModalId).show();
        UIkit.modal('#' + modalId).toggle();
      },
      // モーダル閉じる
      doHideModal(modalId) {
        UIkit.modal('#' + modalId).hide();
        // UIkit.modal('#' + this.vModalId).$destroy(true);
      },
      // 入力エリア表示制御
      doSwitchInputArea(mode) {
        this.doClear();
        this.vUpdateMode = mode
        if (mode == constant.cPageOperationMode.add) {
          // selectオブジェクトのoptionを選択状態にしておく
          this.doSelectedOption('vRetireStatus', mode);
          // selectオブジェクトの表示ラベルを保持しておく
          this.doSetSelectedLabel('vRetireStatus');
        }
        // レコード操作モード表示名設定
        this.$store.commit('common/doSetUpdateModeDisplayName', {
          vUpdateMode: this.vUpdateMode
        })
        // 更新モーダル内メッセージ設定
        this.$store.commit('common/doSetUpdateModalMessage', {
          vUpdateMode: this.vUpdateMode
        })
      },
      // 選択行情報保持
      doStorageRowInfo(key1) {
        if (this.vUpdateMode == constant.cPageOperationMode.change || this.vUpdateMode == constant.cPageOperationMode.delete) {
          // this.vSelectRowIndex = rowIdx;
          // 行色制御
          this.doChangeRowColor(constant.cChangeRowColor.nothing);
          var i = 0;
          for (i = 0; i < this.vTblListData.length; i++) {
            if (this.vTblListData[i].emp_code == key1) {
              this.vEmpCode = key1;
              this.vEmpName = this.vTblListData[i].emp_name;
              this.vEmpKana = this.vTblListData[i].emp_kana;
              this.vEmpWpId = this.vTblListData[i].wp_id;
              this.vEmpWpIdName = this.vTblListData[i].wp_name;
              this.vEmpBirthday = this.vTblListData[i].emp_birthday;
              this.vEmpBirthdayDisp = this.vTblListData[i].emp_birthday_disp;
              this.vEmpSex = this.vTblListData[i].emp_sex;
              this.vEmpMailAddress = this.vTblListData[i].emp_mail_address;
              this.vLoginId = this.vTblListData[i].login_id;
              // this.vLoginPw = this.vTblListData[i].login_pw;
              this.vRetireStatus = this.vTblListData[i].retire_status;
              this.vRetireDate = this.vTblListData[i].retire_date;
              this.vRetireDateDisp = this.vTblListData[i].retire_date_disp;
              break;
            }
          }
          // selectオブジェクトのoptionを選択状態にしておく
          this.doSelectedOption('vEmpWpId');
          this.doSelectedOption('vEmpSex');
          this.doSelectedOption('vRetireStatus');
          // 行選択状態にする
          this.vSelectedRow = true;
          // selectオブジェクトの表示ラベルを保持しておく
          this.doSetSelectedLabel('vEmpWpId');
          this.doSetSelectedLabel('vEmpSex');
          this.doSetSelectedLabel('vRetireStatus');
          // 選択行に色をつける
          this.doChangeRowColor(constant.cChangeRowColor.coloring);
        }
      },
      // 値をクリア
      doClear() {
        this.vUpdateMode = constant.cPageOperationMode.nothing;
        // this.vSelectRowIndex = -1;
        this.vSelectedRow = false;
        // 行色制御
        this.doChangeRowColor(constant.cChangeRowColor.nothing);
        // 選択行情報クリア
        this.vEmpCode = '';
        this.vEmpName = '';
        this.vEmpKana = '';
        this.vEmpWpId = '';
        this.vEmpWpIdName = '';
        this.vEmpBirthday = '';
        this.vEmpBirthdayDisp = '';
        this.vEmpSex = '';
        this.vEmpSexName = '';
        this.vEmpMailAddress = '';
        this.vRetireStatus = '';
        this.vRetireStatusName = '';
        this.vRetireDate = '';
        this.vRetireDateDisp = '';
        // 一括取込情報クリア
        this.vBulkData = []
      },
      // 一覧行の色変更
      // mode:0（行の色を消す）、mode:1（選択行に色をつける）
      doChangeRowColor(mode) {
        var setColor = '';
        if (mode == constant.cChangeRowColor.coloring) {
          setColor = "#e0ffff";
        }
        var tmpInput1 = document.getElementById('cus-emp-' + this.vEmpCode);
        if (tmpInput1) {tmpInput1.style.backgroundColor = setColor}
      },
      // selectオブジェクトを選択状態にする
      doSelectedOption(objId, mode = 0) {
        var loopSelect = 0;
        var obj = document.getElementById(objId);
        var loopBreak = false;
        if (obj) {
          for (loopSelect = 0; loopSelect < obj.options.length; loopSelect++) {
            switch (objId) {
              case 'vEmpWpId':
                // 職場名
                if (obj.options[loopSelect].value == this.vEmpWpId) {
                  obj[loopSelect].selected = true;
                  break;
                }
                break;
              case 'vEmpSex':
                // 性別
                if (obj.options[loopSelect].value == this.vEmpSex) {
                  obj[loopSelect].selected = true;
                  break;
                }
                break;
              case 'vRetireStatus':
                // 退職状態
                // if (obj.options[loopSelect].value == this.vRetireStatus) {
                //   obj[loopSelect].selected = true;
                //   break;
                // }
                if (mode == constant.cPageOperationMode.add) {
                  obj[0].selected = true;
                  this.vRetireStatus = obj.options[obj.selectedIndex].value;
                  loopBreak = true;
                  break;
                } else if (obj.options[loopSelect].value == this.vRetireStatus) {
                  obj[loopSelect].selected = true;
                  loopBreak = true;
                }
                break;
            }
            if (loopBreak) {
              break;
            }
          }
        }
      },
      // selectオブジェクトの表示ラベルをセットする
      doSetSelectedLabel(objId) {
        var obj = document.getElementById(objId);
        if (obj) {
          if (obj.selectedIndex > -1) {
            var txt = obj.options[obj.selectedIndex].text;
            switch (objId) {
              case 'vEmpWpId':
                // 職場名
                this.vEmpWpIdName = txt;
                break;
              case 'vEmpSex':
                // 性別
                this.vEmpSexName = txt;
                break;
              case 'vRetireStatus':
                // 退職状態
                this.vRetireStatusName = txt;
                break;
            }
          }
        }
      },
      // 戻る処理
      doBackToHome() {
        this.$router.push('/customer-home');
      },
      // ID,パスワード照合
      doAuthLoginInfo() {
        if (sessionStorage.getItem('storageId')) {
          let createHash = require("sha256-uint8array").createHash;
          let tmp = createHash().update(sessionStorage.getItem('storagePw')).digest("hex");
          this.$axios.post(constant.cServerRoute.common.login, {
            pLoginId: sessionStorage.getItem('storageId'),
            pLoginPw: tmp
          })
          .then(function(response){
            console.log(response);
            if (response.data.return_result != constant.cServerReturnResult.success) {
              this.$store.state.common.sIsLogined = false;
            }
          }.bind(this))
          .catch(function(error){
            console.log(error);
            this.$store.state.commonn.sIsLogined = false;
          })
        }
      }
    },
    // ページを離れる
    beforeRouteLeave() {
      UIkit.modal('#' + this.vModalId).$destroy(true);
      UIkit.modal('#' + this.vModalIdBulk).$destroy(true);
    }
  }
</script>
